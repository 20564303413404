<template>
    <div>
        <el-row>
            <el-form v-model="params" :inline="true" label-position="right" label-width="110px" size="mini">
                <el-form-item label="配置名称" prop="name">
                    <el-input v-model="params.name" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-select v-model="params.status">
                        <el-option value="" label="全部"></el-option>
                        <el-option v-for="item in statusList" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="doSearch">搜索</el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row>
            <el-col :span="18">
                <el-button type="primary" icon="el-icon-plus" size="mini" v-permission="'waiter:receive:add'" @click="handleAdd">新增</el-button>
            </el-col>
            <el-col :span="6">

            </el-col>
        </el-row>
        <el-table :data="dataList" style="width: 100%" stripe fit max-height="640" v-loading="loading">
            <el-table-column align="center" label="按钮名称" prop="name"></el-table-column>
            <el-table-column align="center" label="按钮展示" prop="label">
                <template v-slot="scope">
                    <el-button :type="scope.row.btnType" size="mini">{{ scope.row.label }}</el-button>
                </template>
            </el-table-column>
            <el-table-column align="center" label="按钮描述" prop="remarks"></el-table-column>
            <el-table-column align="center" label="状态" prop="status">
                <template v-slot="scope">
                    <span v-for="item in statusList" v-if="scope.row.status === item.value">{{ item.label }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="创建人" prop="createBy"></el-table-column>
            <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
            <el-table-column align="center" label="操作" fixed="right">
                <template v-slot="scope">
                    <el-button type="text" size="mini" icon="el-icon-edit-outline" v-permission="'waiter:receive:edit'" @click="handleUpdate(scope.row)">修改</el-button>
                    <el-button type="text" size="mini" icon="el-icon-delete" v-permission="'waiter:receive:delete'">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                @size-change="findPage"
                @current-change="findPage"
                :current-page="params.current"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="params.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
    </div>
</template>

<script>
import * as receiveApi from "@/api/waiter/waiterReceiveBtn.js"

export default {
    name: "WaiterReceive",
    data() {
        return {
            statusList: [{label: '正常', value: 0}, {label: '禁用', value: 9}],
            params: {
                current: 1,
                size: 10,
                status: 0,
            },
            loading: false,
            dataList: [],
            total: 0,
            idList: [],
        }
    },
    mounted() {
        this.findPage()
    },
    methods: {
        /**
         * 启用
         */
        handleEnable(row) {
            let ids = (row.btnId ? [row.btnId] : null) || this.idList
            if (ids) {
                receiveApi.handleEnable(ids).then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: '保存成功！'
                        });
                        this.findPage()
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg
                        });
                    }
                })
            }
        },
        /**
         * 禁用
         */
        handleDisable(row) {
            let ids = (row.btnId ? [row.btnId] : null) || this.idList
            if (ids) {
                receiveApi.handleDisable(ids).then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: '保存成功！'
                        });
                        this.findPage()
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg
                        });
                    }
                })
            }
        },
        /**
         * 修改
         * @param row
         */
        handleUpdate(row) {
            this.$router.push({
                path: `/WaiterReceiveEdit?id=${row.btnId}`
            })
        },
        /**
         * 添加
         */
        handleAdd() {
            this.$router.push({
                path: '/WaiterReceiveEdit'
            })
        },
        /**
         * 重置
         */
        resetQuery() {
            this.params = {
                current: 1,
                size: 10,
                status: 0,
            }
        },
        doSearch() {
            this.params.current = 1
            this.findPage()
        },
        findPage() {
            this.loading = true
            receiveApi.findPage(this.params).then(res => {
                this.loading = false
                if (res.success) {
                    this.dataList = res.data.records
                    this.total = res.data.total
                }
            })
        },
    }
}
</script>

<style scoped>
.el-pagination {
    bottom: 10px;
    position: fixed;
}
</style>